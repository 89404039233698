// FILTER STYLING
form {
  &.admin-filter-form {
    display: flex;
    flex-wrap: wrap;
    text-align: center;
    @include field-standard(4rem 5rem);
    .control-label {
      @include setfont(bold);
      margin-bottom: 5px;
    }
    .form-group {
      &> div {
        margin-top: 10px;
      }
    }
  }
  &.admin-filter-view {
    margin-bottom: 4rem;
    label {
      color: $color-fourth;
      text-transform: uppercase;
      @include setfont(bold);
      text-align: center;
      margin: 2rem 0 1rem 0;
    }
    input[type="text"] {
      border: 1px solid $color-fourth;
    }
  }
}