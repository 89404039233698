
body {
  background-color: $color-third;
  font-family: $font-normal;
  margin-bottom: 10rem;
  @include deviceres(small) {
    font-size: 12px;
  }
}
.shell-logo {
  text-align: center;
  &.big {
    img {
      width: 150px;
      height: auto;
    }
  }
}

// BOOTSTRAP COMPONENTS
.btn {
  font-size: 1.4rem;
  padding: 1.2rem;
  border-radius: 0;
  box-sizing: border-box;
  @include transition(all, 0.35s);
  &.btn-primary {
    @include color-scheme(grey-white,border,hover,reverse);
  }
  &.btn-default {
    @include color-scheme(yellow-grey,border,hover,reverse,font);
  }
  &.btn-warning {
    @include color-scheme(white-red,border,hover);
  }
  &.active {
    @include setfont(bold);
    background-color: darken( $color-primary, 3% );
  }
  &.dropdown-toggle {
    padding: 5px 10px;
    .caret {
      margin: 0 0 0 5px;
    }
    &:focus, &:hover, &:active, .open>.dropdown-toggle.btn-primary {
      background-color: $color-fourth;
      color: $color-primary;
    }
  }
  &.btn-xs {
    padding: 0.3rem;
    font-size: 1.1rem;
    margin: 1px;
  }
  &.btn-lg {
    width: 100%;
  }
  &.btn-sm {

  }
  &.btn-md {
    width: 49%;
    display: inline-block;
  }
}
.jumbotron {
  border-radius: 0;
  @include field-standard();
  &.login {
    @include field-standard(3rem 4rem);
  }
  @include deviceres(small){
    padding: 1rem 2rem;
  }
  .help-block {
    @include setfont(bold);
    font-size: 1.1rem;
  }
}
.well {
  font-size: 1.2rem;
  background-color: $color-primary;
  color: $color-fourth;
  border-radius: 0px;
  border: none;
  box-shadow: none;

  .item {
    text-align: left;
    padding: 15px 20px;
  }
}
.alert {
  border: none;
  border-radius: 0px;

  &.alert-info {
    background-color: $color-fourth;
    color: $color-third;
  }
}
.checkbox {
  margin-top: 0px;
}
.col-centered {
  float: none;
  margin: 0 auto;
}

// TABLE STYLES
%table-padding-standard {
  & > thead > tr > th {
    border-bottom: 0;
    padding: 1.2rem 0 1.2rem 0rem;
    &:first-child {
      padding: 1.2rem 0 1.2rem 1.2rem;
    }
    &:last-child {
      padding: 1.2rem 1.2rem 1.2rem 0;
    }
  }
}
table {
  width: 100%;
  thead {
    th:last-child {
        text-align: right;
      }
  }
  tbody {
    tr {
      td {
        padding: 2px 0;
        &:first-child {
          @include setfont(bold);
        }
        &:last-child {
          text-align: right;
        }
      }
      th {
        vertical-align: middle !important;
      }
      th:last-child {
        text-align: right;
      }
    }
  }
  &.table-striped {
    width: 100%;
    font-size: 1.2rem;
    @extend %table-padding-standard;
    & > tbody {
      & > tr > td {
        border: none;
        padding: 0;
        height: 50px;
        vertical-align: middle;
        &:first-child {
          padding: 0 0 0 1.2rem;
        }
      }
      & > tr:nth-child(odd) > td {
        background-color: rgba($color-fourth,0.05);
      }
    }
  }
  &.table-condensed {
    & > tbody > tr > td, & > thead > tr > th {
      &:last-child {
        text-align: right;
        padding-right: 1.2rem;
      }
    }
  }
  &.detail-view {
    @extend %table-padding-standard;
    & > tbody > tr > td { padding: 20px; }
    tr {
      th, td{
        background-color: rgba($color-field-background,0.5) !important;
        border: none;
      }
      td {
        padding-right: 20px;
      }
      &:nth-child(odd)>td, &:nth-child(odd)>th {
        background-color: $color-field-background !important;
      }
    }

  }
}
.summary {
  margin-bottom: 3rem;
  text-align: center;
}
.pagination {
  li {
    a {
      @include color-scheme(yellow-grey,border,hover,font,reverse);
    }
    &.active a {
      @include color-scheme(grey-white,border,hover,reverse);
    }
  }
}
