/* Project specific variables */

// Use right path to fonts for font awesome
$fa-font-path: "../fonts/font-awesome";

// Edit container-size for mobile
$container-sm: inherit;
$container-md: inherit;

// Breakpoint for Mobile-Navigation
$grid-float-breakpoint: 900px;

$font-normal: 'Roboto', sans-serif;
$font-bold: 'Roboto', sans-serif;
$font-light: 'Roboto', sans-serif;

//** Border color for table and cell borders.
$table-border-color: #404040;
