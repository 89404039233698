.navbar {
  background-color: rgba(255,255,255,1.0);
  border-bottom: 1px solid $color-field-background;
  .navbar-brand {
    img {
      display: inline-block;
      vertical-align: middle;
      width: 30px;
      margin-top: -3px;
    }
    @include setfont(bold);
    span {
      @include setfont(bold);
      vertical-align: middle;
      margin-left: 15px;
      &.admin {
        @include setfont(bold);
        border: 2px solid $color-secondary;
        color: $color-secondary;
        padding: 5px;
        font-size: 1rem;
        border-radius: 4px;
      }
      @include deviceres(small) {
        font-size: 1.3rem;
        &.admin {
          font-size: 0.6rem;
        }
      }
    }
  }
  .navbar-header {
    .navbar-toggle {
      background-color: transparent;
      border: none;
      .icon-bar {
        background-color: $color-fourth;
      }
    }
  }
  .navbar-collapse {
    @media screen and(max-width: $grid-float-breakpoint) {
      ul {
        display: block;
        p {
          padding-left: 15px;
          @include setfont(bold);
        }
      }
      .pull-right {
        float: none !important;
        border-top: 1px solid $color-field-background;
      }
    }
    @include deviceres(big){
      font-size: 1.3rem;
      ul {
        p {
          font-size: 1.3rem;
        }
      }
    }
  }
  .navbar-nav {
    li {
      &:hover {
        background-color: $color-field-background;
        color: $color-secondary;
      }
      &.active {
        background-color: $color-field-background;
      }
    }

  }
}