// MIXINS AND HELPER CLASSES

// Media Breakpoints
$break-minimal: 330px;
$break-small: 570px;
$break-medium: 768px;
$break-big: 1024px;

// MIXINS
@mixin deviceres($media) {
  @if $media == minimal {
    @media screen and(max-width: $break-minimal) { @content; }
  }
  @if $media == small {
    @media screen and(max-width: $break-small) { @content; }
  }
  @else if $media == medium {
    @media screen and(max-width: $break-medium) { @content; }
  }
  @else if $media == big {
    @media screen and(max-width: $break-big) { @content; }
  }
}

@mixin transition($value,  $duration) {
  -webkit-transition: $value $duration;
  -moz-transition: $value $duration;
  -ms-transition: $value $duration;
  -o-transition: $value $duration;
  transition: $value $duration;
}

@mixin field-standard($padding: null) {
  background-color: $color-field-background;
  @if $padding {
    padding: $padding;
    @include deviceres(big) {
      padding: 2rem;
    }
  }
}

@mixin color-scheme ($parameters...) {
  $color-foreground: $color-fourth;
  $color-background: $color-third;
  $color-border: $color-fourth;
  $border: false;
  $hover: false;
  $change-font-color: true;
  $reverse: false;

  @each $params in $parameters {
    @if $params == "border" { $border: true; }
    @if $params == "hover" { $hover: true; }
    @if $params == "font" { $change-font-color: false; }
    @if $params == "reverse" { $reverse: true; }
  }

  @each $params in $parameters {
    @if $params == "yellow-grey" {
      $color-foreground: if($reverse==false,$color-primary,$color-fourth);
      $color-background: if($reverse==false,$color-fourth,$color-primary);
      $color-border: if($reverse==false,$color-fourth,$color-primary);
    }
    @if $params == "white-red" {
      $color-foreground: if($reverse==false,$color-third,$color-secondary);
      $color-background: if($reverse==false,$color-secondary,$color-third);
      $color-border: if($reverse==false,$color-secondary,$color-secondary);
    }
    @if $params == "grey-white" {
      $color-foreground: if($reverse==false,$color-fourth,$color-third);
      $color-background: if($reverse==false,$color-third,$color-fourth);
      $color-border: if($reverse==false,$color-fourth,$color-fourth);
    }
  }

  color: $color-foreground;
  background-color: $color-background;
  @if $border == true and $hover == false {
    border: 1px solid $color-border;
  }
  @else if $border == true and $hover == true {
    border: 1px solid $color-border;
    @include transition(all,0.35s);
    &:hover {
      background-color: rgba($color-background,0);
      border: 1px solid $color-border;
      color: if($change-font-color==true,$color-background,$color-foreground);
    }
  }
  @else if $border == false and $hover == true {
    border: none;
    @include transition(all,0.35s);
    &:hover {
      background-color: rgba($color-background,0);
      border: none;
      color: if($change-font-color==true,$color-background,$color-foreground);
    }
  }
}

@mixin setfont($type) {
  @if $type == normal {
    font-family: $font-normal;
  }
  @else if $type == light {
    font-family: $font-light;
  }
  @else if $type == bold {
    font-family: $font-bold;
    font-weight: 900;
  }
}

// HELPER CLASSES
.flex {
  display: flex;
  .flex-child {
    flex: 1;
    &.right {
      text-align: right;
    }
  }
}
.spacer {
  &.top {
    margin-top: 5rem;
  }
  &.bottom {
    margin-bottom: 5rem;
  }
  &.bottom-sm {
    margin-bottom: 2rem;
  }
  &.top-sm {
    margin-top: 2rem;
  }
}
.flex-basis {
  &.full {
    flex-basis: 100%;
  }
  &.half {
    flex-basis: 50%
  }
  &.third {
    flex-basis: 33%;
  }
}
.text {
  &.left {
    text-align: left;
  }
}
