
// STANDARD H-STYLES
%headlines {
  @include setfont(bold);
  color: $color-fourth;
  text-transform: uppercase;
  text-align: center;
}
// STANDARD TAGS
h1 {
  margin-bottom: 4rem;
  @extend %headlines;
  small {
    color: $color-secondary;
  }
  @include deviceres(small){
    font-size: 25px;
  }
  &.login {
    text-transform: none;
  }
}
h2 {
  @extend %headlines;
  margin-bottom: 3rem;
  @include deviceres(small){
    font-size: 21px;
    margin: 1rem 0 2rem 0;
  }

}
h3, h4 {
  @extend %headlines;
  margin: 1.5rem 0;
}
p {
  font-size: 1.5rem;
  line-height: 2.2rem;
  margin-bottom: 3rem;
  text-align: justify;
}
a {
  color: $color-fourth;
  @include transition(all, 0.35s);
  &:focus {
    color: $color-fourth;
  }
  &:hover {
    color: $color-secondary;
  }
}
small {
  display: block;
  padding: 15px 0;
}
label {
  font-weight: normal;
}
input {
  border: 1px solid $color-third;
  &[type="text"], &[type="password"]{
    display: inline-block;
    text-align: left;
    outline: 0;
    border: 1px solid $color-third;
    padding: 1.3rem 1rem;
    font-size: 1.5rem;
    line-height: 1.5rem;
    font-weight: 400;
    background-color: $color-third;
    border-radius: 0;
    transition: .5s;
    box-shadow: none;
    height: auto;
    @include transition(border, 0.35s);
    &:hover {
      border: 1px solid $color-fourth;
    }
    @include deviceres(small) {
      padding: 0.9rem 1rem;
    }
  }
  &[type="checkbox"] {
    margin-top: 2px;
  }
  &.has-error {
    border: 1px solid $color-secondary;
  }
  &.has-success {
    border: 1px solid $color-primary
  }
}

.choose-big {
  .control-label {
    @include setfont(bold);
    color: $color-fourth !important;
    text-transform: uppercase;
    text-align: center;
    font-size: 30px;
    margin-bottom: 10px;
  }
  input[type="radio"] {
    opacity:0;
  }
  .radio-inline {
    span {
      font-size: 20px;
      font-weight: 700;
      text-transform: uppercase;
      display: inline-block;
      &::before {
        display: inline-block;
        font-family: "FontAwesome";
        font-size: 35px;
        color: $color-fourth;
        font-weight: 100;
        vertical-align: -10%;
        padding-right: 7px;
      }
    }
    input[type="radio"] + span::before {
      content: $fa-var-circle-o;
    }
    input[type="radio"]:checked + span::before {
      content: $fa-var-check-circle-o;
      color: $color-secondary;
    }
  }

}
// input error messaage
.help-block {
  @include setfont(bold);
  font-size: 1.1rem;
}

// CUSTOM CLASSES
.price-label {
  text-align: center;
  font-size: 2rem;
  &.big {
    font-size: 5rem;
  }
  sup {
    font-size: 3.75rem;
  }
}
.lead {
  padding: 15px 0;
  font-size: 1.8rem;
}
.site-signup {
  p {
    text-align: center;
  }
}
.centered {
  text-align: center;
  &.bold {
    font-weight: 700;
  }
}
